<template>
    <div class="statement_container">
        <img :src="require('@/assets/images/live/sign_cover.png')"/>
        <div class="statement_mask_container">
            <div class="statement_text_container">
                <div class="statement_title_text">声明</div>
                <div class="statement_content_text">百展讲堂旨在为公众和医疗卫生专业人士提供便捷的方式获取和了解关于肿瘤治疗的专业知识，由于科学研究和临床实践的不断发展与充实，医学知识日益更新，专家的见解也可能存在差异，本平台所涵内容并非旨在提供专业意见,仅供交流与参考，如果您想了解更多本平台中出现的与疾病或药物有关的信息，请务必咨询医疗卫生专业人士。</div>  
                <div class="statement_button">
                    <el-button type='primary' @click.native="doJoin" >进入互动课堂</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'statement',
    methods:{
        doJoin(){
            this.$router.push({
                path:`/sign`,
                query:this.$route.query,
            });
        }
    }
}
</script>
<style>
.statement_container{
    width: 100%;
    height: 100%;
}
.statement_container img{
    width: 100%;
    height: auto;
}
.statement_mask_container{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #00000075;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 12vw;
}
.statement_text_container{
    padding: 0 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
}
.statement_title_text{
    font-weight: 600;
    text-align: center;
    padding: 4vw 0;
}
.statement_content_text{
    line-height: 6vw;
    word-break: break-all;
}
.statement_button{
    padding: 4vw 0;
    display: flex;
    justify-content: center;
}
</style>